import * as React from "react";
import {Helmet} from "react-helmet";
import Layout from "../components/Layout";
import Boxed from "../components/Boxed";
import TextContent from "../components/TextContent";
import UserAgreementTR from "content/UserAgreementTR";

const PrivacyPolicy = () => {
  return <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Kullanıcı Sözleşmesi ve Gizlilik Politikası | Paramla</title>
    </Helmet>
    <Boxed>
      <TextContent title="Kullanıcı Sözleşmesi ve Gizlilik Politikası">
        <UserAgreementTR />
      </TextContent>
    </Boxed>
  </Layout>
}

export default PrivacyPolicy;